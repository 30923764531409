import React from "react";

import AchievementsContainer from "components/Profile/Achievements/AchievementsContainer";
import BookmarksContainer from "components/Project/Bookmarks/BookmarksContainer";
import ItemContainer from "components/Profile/Item/ItemContainer";
import LoginHomeContainer from "components/Login/LoginHomeContainer";
import LoginContainer from "components/Login/LoginContainer";
import LoginSSOContainer from "components/Login/LoginSSOContainer";
import PostLoginInterceptContainer from "components/Login/PostLogin/PostLoginInterceptContainer";
import TourContainer from "components/shared/Tour/TourContainer";
import FrameLoginSuccessContainer from "components/Login/FrameLoginSuccessContainer";
import SSOLoginSuccessContainer from "components/SSOLogin/SSOLoginSuccessContainer";
import ProjectsListContainer from "components/ProjectsList/ProjectsListContainer";
import NotificationsContainer from "components/Profile/Notifications/NotificationsContainer";
import InboxContainer from "components/Profile/Inbox/InboxContainer";
import ProfileContainer from "components/Profile/ProfileContainer";
import TeamContainer from "components/Profile/Team/TeamContainer";
import FrameContainer from "components/Frame/FrameContainer";
import FollowsContainer from "components/Profile/Follows/FollowsContainer";

import ProjectContainer from "components/Project/ProjectContainer";
import TopicContainer from "components/Topic/TopicContainer";
import ChallengeContainer from "components/Challenge/ChallengeContainer";
import ChallengeAFriendContainer from "components/Challenge/ChallengeAFriend/ChallengeAFriendContainer";
import ActivityContainer from "components/Project/Activity/ActivityContainer";
import MyActivityContainer from "components/Project/Activity/MyActivityContainer";
import LeaderboardContainer from "components/Project/Leaderboard/LeaderboardContainer";
import RewardsContainer from "components/Project/Rewards/RewardsContainer";
import SearchChallengeContainer from "components/Topic/SearchChallengeContainer";
import ProjectReferAFriendContainer from "components/Project/ProjectReferAFriend/ProjectReferAFriendContainer";
import TeamsContainer from "components/Project/Teams/TeamsContainer";
import TeamCreateContainer from "components/Project/Teams/Create/TeamCreateContainer";
import TeamInviteContainer from "components/Profile/Team/Invite/TeamInviteContainer";
import TeamUpdateContainer from "components/Profile/Team/Update/TeamUpdateContainer";
import UsersContainer from "components/Project/Users/UsersContainer";
import EnquiryContainer from "components/shared/Enquiry/EnquiryContainer";
import CommentsContainer from "components/Comments/CommentsContainer";
import CommentsThreadContainer from "components/Comments/CommentsThread/CommentsThreadContainer";
import DiscussionsContainer from "components/Discussions/DiscussionsContainer";
import NewPostContainer from "components/Discussions/Post/NewPostContainer";
import DiscussionCommentsContainer from "components/Discussions/DiscussionComments/DiscussionCommentsContainer";
import CertificatesContainer from "components/Profile/Certificates/CertificatesContainer";

import Error404Container from "components/ErrorPages/Error404Container";
import SettingsContainer from "components/Settings/SettingsContainer";
import ResetPasswordContainer from "components/Login/ResetPasswordContainer";
import ResendActivationContainer from "components/Login/ResendActivationContainer";
import RegisterContainer from "components/Login/RegisterContainer";

import UpdateDeviceTokenContainer from "components/UpdateDeviceToken/UpdateDeviceTokenContainer";

// for Gametize app layout
import HomeContainer from "components/Home/HomeContainer";
import MyProjectsContainer from "components/ProjectsList/MyProjectsContainer";
import FeaturedProjectsContainer from "components/ProjectsList/FeaturedProjectsContainer";
import SearchProjectsContainer from "components/ProjectsList/SearchProjectsContainer";
import GameContainer from "components/Game/GameContainer";

import {
  SINGLE_PROJECT_APP,
  USE_GAMETIZE_APP_LAYOUT,
  USE_DEPRECATED_FEATURED_GAMES_API,
  ENABLE_FEATURED_GAMES,
} from "config";

const // general
  _LOGIN_HOME = "LOGIN_HOME",
  _LOGIN = "LOGIN",
  _LOGIN_SSO = "LOGIN_SSO",
  _POST_LOGIN_INTERCEPT = "POST_LOGIN_INTERCEPT",
  _LOGIN_TOUR = "LOGIN_TOUR",
  _RESEND_ACTIVATION = "RESEND_ACTIVATION",
  _RESET_PASSWORD = "RESET_PASSWORD",
  _PROFILE = "MY_PROFILE",
  _USER = "USER",
  _USER_FOLLOWS = "USER_FOLLOWS",
  _USER_ACHIEVEMENTS = "USER_ACHIEVEMENTS",
  _ACHIEVEMENTS = "ACHIEVEMENTS",
  _BOOKMARKS = "BOOKMARKS",
  _NOTIFICATIONS = "NOTIFICATIONS",
  _INBOX = "INBOX",
  _ITEM = "ITEM",
  _TEAM = "TEAM",
  _TEAM_INVITE = "TEAM_INVITE",
  _TEAM_UPDATE = "TEAM_UPDATE",
  _ERROR = "ERROR",
  _SETTINGS = "SETTINGS",
  _UPDATE_DEVICE_TOKEN = "UPDATE_DEVICE_TOKEN",
  _REGISTER = "REGISTER",
  _PROJECTS_LIST = "PROJECTS_LIST",
  // frames
  _FRAME = "FRAME",
  _PROJECT_FRAME = "PROJECT_FRAME",
  // comments
  _TOPIC_COMMENTS = "TOPIC_COMMENTS",
  _CHALLENGE_COMMENTS = "CHALLENGE_COMMENTS",
  _CLAIM_COMMENTS = "CLAIM_COMMENTS",
  // comments threads
  _TOPIC_COMMENTS_THREAD = "TOPIC_COMMENTS_THREAD",
  _CHALLENGE_COMMENTS_THREAD = "CHALLENGE_COMMENTS_THREAD",
  _CLAIM_COMMENTS_THREAD = "CLAIM_COMMENTS_THREAD",
  // project
  _PROJECT_HOME = "PROJECT_HOME",
  _TOPIC_CATEGORY = "TOPIC_CATEGORY",
  _TOPIC = "TOPIC",
  _CHALLENGE_CATEGORY = "CHALLENGE_CATEGORY",
  _CHALLENGE = "CHALLENGE",
  _CHALLENGE_A_FRIEND = "CHALLENGE_A_FRIEND",
  _CHALLENGE_ACTIVITY = "CHALLENGE_ACTIVITY",
  _CHALLENGE_MY_ACTIVITY = "CHALLENGE_MY_ACTIVITY",
  _CLAIM = "CLAIM",
  _PROJECT_ACHIEVEMENTS = "PROJECT_ACHIEVEMENTS",
  _PROJECT_USER_ACHIEVEMENTS = "PROJECT_USER_ACHIEVEMENTS",
  _PROJECT_USER_FOLLOWS = "PROJECT_USER_FOLLOWS",
  _PROJECT_ACTIVITY = "PROJECT_ACTIVITY",
  _PROJECT_BOOKMARKS = "PROJECT_BOOKMARKS",
  _PROJECT_LEADERBOARD = "PROJECT_LEADERBOARD",
  _PROJECT_NOTIFICATIONS = "PROJECT_NOTIFICATIONS",
  _PROJECT_INBOX = "PROJECT_INBOX",
  _PROJECT_TEAM_INBOX = "PROJECT_TEAM_INBOX",
  _PROJECT_PROFILE = "PROJECT_PROFILE",
  _PROJECT_REWARDS = "PROJECT_REWARDS",
  _PROJECT_TEAMS = "PROJECT_TEAMS",
  _PROJECT_TEAM_CREATE = "PROJECT_TEAM_CREATE",
  _PROJECT_SEARCH = "PROJECT_SEARCH",
  _PROJECT_USER = "PROJECT_USER",
  _PROJECT_USERS = "PROJECT_USERS",
  _PROJECT_REFER_A_FRIEND = "PROJECT_REFER_A_FRIEND",
  // discussions
  _PROJECT_DISCUSSIONS = "PROJECT_DISCUSSIONS",
  _PROJECT_NEW_POST = "PROJECT_NEW_POST",
  _PROJECT_BOARD_POSTS = "PROJECT_BOARD_POSTS",
  _PROJECT_BOARD_NEW_POST = "PROJECT_BOARD_NEW_POST",
  _PROJECT_POST_COMMENTS = "PROJECT_POST_COMMENTS",
  // enquiry
  _CHALLENGE_ENQUIRY = "CHALLENGE_ENQUIRY",
  _PROJECT_USER_ENQUIRY = "PROJECT_USER_ENQUIRY",
  _CLAIM_ENQUIRY = "CLAIM_ENQUIRY",
  // project - for sandboxing
  _PROJECT_LOGIN_HOME = "PROJECT_LOGIN_HOME",
  _PROJECT_LOGIN = "PROJECT_LOGIN",
  _PROJECT_POST_LOGIN_INTERCEPT = "PROJECT_POST_LOGIN_INTERCEPT",
  _PROJECT_LOGIN_SSO = "PROJECT_LOGIN_SSO",
  _PROJECT_REGISTER = "PROJECT_REGISTER",
  _PROJECT_SETTINGS = "PROJECT_SETTINGS",
  _PROJECT_RESET_PASSWORD = "PROJECT_RESET_PASSWORD",
  _PROJECT_TEAM = "PROJECT_TEAM",
  _PROJECT_TEAM_INVITE = "PROJECT_TEAM_INVITE",
  _PROJECT_TEAM_UPDATE = "PROJECT_TEAM_UPDATE",
  _PROJECT_ITEM = "PROJECT_ITEM",
  // Certivate
  _PROJECT_CERTIFICATES = "PROJECT_CERTIFICATES",
  _PROJECT_USER_CERTIFICATES = "PROJECT_USER_CERTIFICATES",
  _CERTIFICATES = "CERTIFICATES",
  _USER_CERTIFICATES = "USER_CERTIFICATES",
  // sso logins
  _SSO_LOGIN_SUCCESS = "SSO_LOGIN_SUCCESS",
  _PROJECT_SSO_LOGIN_SUCCESS = "PROJECT_SSO_LOGIN_SUCCESS",
  _FRAME_LOGIN_SUCCESS = "FRAME_LOGIN_SUCCESS",
  _PROJECT_FRAME_LOGIN_SUCCESS = "PROJECT_FRAME_LOGIN_SUCCESS",
  // for Gametize app layout
  _GAME = "GAME",
  _HOME = "HOME",
  _FEATURED_PROJECTS = "FEATURED_PROJECTS",
  _SEARCH_PROJECTS = "SEARCH_PROJECTS",
  _MY_PROJECTS = "MY_PROJECTS";

export class Page {
  constructor(name, component) {
    this._name = name;
    this._component = component;
  }

  get component() {
    return this._component;
  }

  get topbarState() {
    switch (this._name) {
      case _LOGIN_HOME:
      case _PROJECT_LOGIN_HOME:
        return "login-home";
      case _LOGIN:
      case _LOGIN_SSO:
      case _SSO_LOGIN_SUCCESS:
      case _PROJECT_LOGIN:
      case _PROJECT_LOGIN_SSO:
      case _PROJECT_SSO_LOGIN_SUCCESS:
        return "login";
      case _LOGIN_TOUR:
        return "login-tour";
      case _HOME:
        return "home";
      case _REGISTER:
      case _PROJECT_REGISTER:
        return "login-secondary";
      case _RESEND_ACTIVATION:
        return "resend-activation";
      case _RESET_PASSWORD:
      case _PROJECT_RESET_PASSWORD:
        return "reset-password";
      case _POST_LOGIN_INTERCEPT:
      case _PROJECT_POST_LOGIN_INTERCEPT:
        return "post-login";
      case _PROJECTS_LIST:
        /*
         If Gametize layout is used:
         - If deprecated featured projects API is used: Project list is the "home" page
         - If we use project list page as featured projects: Project list is not the "home" page

         If Gametize layout is not used:
         - Project list is the "home" page for multi-project app
        */
        return !USE_GAMETIZE_APP_LAYOUT ||
          (USE_DEPRECATED_FEATURED_GAMES_API && ENABLE_FEATURED_GAMES)
          ? "home-multi"
          : "projects-list";
      case _PROJECT_HOME:
        return SINGLE_PROJECT_APP ? "home-single" : "projecthome";
      case _PROJECT_ACHIEVEMENTS:
      case _PROJECT_USER_ACHIEVEMENTS:
      case _PROJECT_USER_FOLLOWS:
      case _PROJECT_BOOKMARKS:
      case _PROJECT_REFER_A_FRIEND:
      case _PROJECT_NOTIFICATIONS:
      case _PROJECT_INBOX:
      case _PROJECT_TEAM_INBOX:
      case _PROJECT_PROFILE:
      case _PROJECT_SEARCH:
      case _PROJECT_USER:
      case _PROJECT_USER_ENQUIRY:
      case _PROJECT_USERS:
      case _PROJECT_SETTINGS:
      case _PROJECT_ITEM:
      case _PROJECT_TEAM_CREATE:
      case _TEAM_UPDATE:
      case _PROJECT_TEAM_UPDATE:
      case _TEAM_INVITE:
      case _PROJECT_TEAM_INVITE:
      case _PROJECT_FRAME:
      case _FRAME:
      case _PROJECT_CERTIFICATES:
      case _PROJECT_USER_CERTIFICATES:
      case _PROJECT_TEAM:
        return "project";
      case _PROJECT_TEAMS:
      case _PROJECT_LEADERBOARD:
      case _PROJECT_REWARDS:
      case _PROJECT_ACTIVITY:
        return "project-secondary";
      case _PROJECT_DISCUSSIONS:
      case _PROJECT_BOARD_POSTS:
        return "discussions-posts";
      case _PROJECT_BOARD_NEW_POST:
      case _PROJECT_NEW_POST:
        return "discussions-new-post";
      case _PROJECT_POST_COMMENTS:
        return "discussions-comments";
      case _TOPIC_CATEGORY:
        return "topic-category";
      case _CHALLENGE_CATEGORY:
        return "challenge-category";
      case _TOPIC:
      case _TOPIC_COMMENTS:
        return "topic";
      case _TOPIC_COMMENTS_THREAD:
        return "topic-comments-thread";
      case _CHALLENGE:
        return "challenge";
      case _CHALLENGE_COMMENTS:
        return "challenge-comments";
      case _CHALLENGE_COMMENTS_THREAD:
        return "challenge-comments-thread";
      case _CHALLENGE_ACTIVITY:
      case _CHALLENGE_MY_ACTIVITY:
      case _CHALLENGE_ENQUIRY:
      case _CLAIM:
      case _CLAIM_ENQUIRY:
      case _CHALLENGE_A_FRIEND:
        return "challenge-subpage";
      case _CLAIM_COMMENTS:
        return "claim-comments";
      case _CLAIM_COMMENTS_THREAD:
        return "claim-comments-thread";
      case _PROFILE:
        return "profile";
      case _UPDATE_DEVICE_TOKEN:
      case _FRAME_LOGIN_SUCCESS:
      case _PROJECT_FRAME_LOGIN_SUCCESS:
        return "no-topbar";
      case _FEATURED_PROJECTS:
      case _MY_PROJECTS:
        return "projects-list";
      case _SEARCH_PROJECTS:
        return "search-projects";
      default:
        return "default";
    }
  }

  get topbarTitle() {
    switch (this._name) {
      case _LOGIN:
      case _LOGIN_SSO:
      case _PROJECT_LOGIN:
      case _PROJECT_LOGIN_SSO:
      case _SSO_LOGIN_SUCCESS:
      case _FRAME_LOGIN_SUCCESS:
      case _PROJECT_SSO_LOGIN_SUCCESS:
      case _PROJECT_FRAME_LOGIN_SUCCESS:
        return "_LOGIN";
      case _LOGIN_TOUR:
        return "_LOGIN_TOUR";
      case _REGISTER:
      case _PROJECT_REGISTER:
        return "_REGISTER";
      case _RESEND_ACTIVATION:
        return "_RESEND_ACTIVATION";
      case _RESET_PASSWORD:
      case _PROJECT_RESET_PASSWORD:
        return "_RESET_PASSWORD";
      case _PROJECTS_LIST:
        return "_PROJECTS_LIST";
      case _PROFILE:
        return "_PROFILE";
      case _USER:
        return "_USER";
      case _PROJECT_PROFILE:
        return "_PROJECT_PROFILE";
      case _PROJECT_USER:
        return "_PROJECT_USER";
      case _PROJECT_USERS:
        return "_PROJECT_USERS";
      case _ACHIEVEMENTS:
        return "_ACHIEVEMENTS";
      case _USER_ACHIEVEMENTS:
        return "_USER_ACHIEVEMENTS";
      case _PROJECT_ACHIEVEMENTS:
        return "_PROJECT_ACHIEVEMENTS";
      case _PROJECT_USER_ACHIEVEMENTS:
        return "_PROJECT_USER_ACHIEVEMENTS";
      case _PROJECT_USER_FOLLOWS:
        return "_PROJECT_USER_FOLLOWS";
      case _PROJECT_USER_ENQUIRY:
        return "_PROJECT_USER_ENQUIRY";
      case _PROJECT_ACTIVITY:
        return "_PROJECT_ACTIVITY";
      case _CHALLENGE_ACTIVITY:
        return "_CHALLENGE_ACTIVITY";
      case _CHALLENGE_A_FRIEND:
        return "_CHALLENGE_A_FRIEND";
      case _CHALLENGE_ENQUIRY:
        return "_CHALLENGE_ENQUIRY";
      case _CLAIM:
        return "_CLAIM";
      case _CLAIM_ENQUIRY:
        return "_CLAIM_ENQUIRY";
      case _CLAIM_COMMENTS:
        return "_CLAIM_COMMENTS";
      case _CLAIM_COMMENTS_THREAD:
        return "_CLAIM_COMMENTS_THREAD";
      case _BOOKMARKS:
        return "_BOOKMARKS";
      case _PROJECT_BOOKMARKS:
        return "_PROJECT_BOOKMARKS";
      case _NOTIFICATIONS:
        return "_NOTIFICATIONS";
      case _PROJECT_NOTIFICATIONS:
        return "_PROJECT_NOTIFICATIONS";
      case _INBOX:
      case _PROJECT_INBOX:
      case _PROJECT_TEAM_INBOX:
        return "_INBOX";
      case _PROJECT_LEADERBOARD:
        return "_PROJECT_LEADERBOARD";
      case _PROJECT_REFER_A_FRIEND:
        return "_PROJECT_REFER_A_FRIEND";
      case _PROJECT_REWARDS:
        return "_PROJECT_REWARDS";
      case _PROJECT_TEAMS:
        return "_PROJECT_TEAMS";
      case _PROJECT_TEAM_CREATE:
        return "_PROJECT_TEAM_CREATE";
      case _GAME:
        return "_GAME";
      case _PROJECT_HOME:
        return "_PROJECT_HOME";
      case _TOPIC_CATEGORY:
        return "_TOPIC_CATEGORY";
      case _PROJECT_SEARCH:
        return "_PROJECT_SEARCH";
      case _TOPIC:
        return "_TOPIC";
      case _CHALLENGE_CATEGORY:
        return "_CHALLENGE_CATEGORY";
      case _TOPIC_COMMENTS:
        return "_TOPIC_COMMENTS";
      case _TOPIC_COMMENTS_THREAD:
        return "_TOPIC_COMMENTS_THREAD";
      case _CHALLENGE:
        return " _CHALLENGE";
      case _CHALLENGE_COMMENTS:
        return "_CHALLENGE_COMMENTS";
      case _CHALLENGE_COMMENTS_THREAD:
        return "_CHALLENGE_COMMENTS_THREAD";
      case _PROJECT_DISCUSSIONS:
      case _PROJECT_BOARD_POSTS:
        return "_PROJECT_BOARD_POSTS";
      case _PROJECT_BOARD_NEW_POST:
      case _PROJECT_NEW_POST:
        return "_PROJECT_BOARD_NEW_POST";
      case _PROJECT_POST_COMMENTS:
        return "_PROJECT_POST_COMMENTS";
      case _ITEM:
      case _PROJECT_ITEM:
        /*
          Default topbar title is set in Pages.js, under topbarTitle() as "_ITEM".
          "_ITEM" points to button_home_rewards in setTitle() in TopbarContainer.js (default).
          This is further differentiated between Rewards and Achievements during a successful
          API call in ItemContainer.js, in getItem().
        */
        return "_ITEM";
      case _TEAM:
      case _PROJECT_TEAM:
        return "_TEAM";
      case _TEAM_INVITE:
      case _PROJECT_TEAM_INVITE:
        return "_TEAM_INVITE";
      case _TEAM_UPDATE:
      case _PROJECT_TEAM_UPDATE:
        return "_TEAM_UPDATE";
      case _SETTINGS:
      case _PROJECT_SETTINGS:
        return "_SETTINGS";
      case _ERROR:
        return "_ERROR";
      case _HOME:
        return "_HOME";
      case _FEATURED_PROJECTS:
        return "_FEATURED_PROJECTS";
      case _SEARCH_PROJECTS:
        return "_SEARCH_PROJECTS";
      case _MY_PROJECTS:
        return "_MY_PROJECTS";
      case CERTIFICATES:
        return "CERTIFICATES";
      case _USER_CERTIFICATES:
        return "_USER_CERTIFICATES";
      case _PROJECT_CERTIFICATES:
        return "_PROJECT_CERTIFICATES";
      case _PROJECT_USER_CERTIFICATES:
        return "_PROJECT_USER_CERTIFICATES";
      default:
        return null;
    }
  }

  get sidebarState() {
    switch (this._name) {
      case _PROJECT_HOME:
      case _TOPIC_CATEGORY:
        return "project";
      case _PROJECT_ACHIEVEMENTS:
      case _PROJECT_USER_ACHIEVEMENTS:
      case _PROJECT_USER_FOLLOWS:
      case _PROJECT_ACTIVITY:
      case _PROJECT_BOOKMARKS:
      case _PROJECT_LEADERBOARD:
      case _PROJECT_NOTIFICATIONS:
      case _PROJECT_PROFILE:
      case _PROJECT_REWARDS:
      case _PROJECT_TEAMS:
      case _PROJECT_TEAM_CREATE:
      case _PROJECT_SEARCH:
      case _PROJECT_USER:
      case _PROJECT_USERS:
      case _PROJECT_TEAM:
      case _PROJECT_ITEM:
      case _PROJECT_CERTIFICATES:
      case _PROJECT_USER_CERTIFICATES:
        return "project-secondary";
      case _TOPIC:
      case _CHALLENGE_CATEGORY:
      case _TOPIC_COMMENTS:
      case _TOPIC_COMMENTS_THREAD:
      case _CHALLENGE:
      case _CHALLENGE_COMMENTS:
      case _CHALLENGE_COMMENTS_THREAD:
      case _CHALLENGE_ACTIVITY:
      case _CHALLENGE_MY_ACTIVITY:
      case _CLAIM:
      case _CLAIM_COMMENTS:
      case _CLAIM_COMMENTS_THREAD:
        return "topic";
      case _PROJECT_DISCUSSIONS:
        return "project-secondary";
      case _PROJECT_BOARD_POSTS:
      case _PROJECT_POST_COMMENTS:
        return "board";
      case _PROJECT_NEW_POST:
      case _PROJECT_BOARD_NEW_POST: // not suitable to show board since dropdown list can be changed
        return "project-secondary";
      default:
        return null;
    }
  }

  /* For floating buttons */
  get floatingButtonsState() {
    switch (this._name) {
      case _PROJECT_ACHIEVEMENTS:
      case _PROJECT_USER_ACHIEVEMENTS:
      case _PROJECT_USER_FOLLOWS:
      case _PROJECT_ACTIVITY:
      case _PROJECT_BOOKMARKS:
      case _PROJECT_LEADERBOARD:
      case _PROJECT_NOTIFICATIONS:
      case _PROJECT_PROFILE:
      case _PROJECT_REWARDS:
      case _PROJECT_TEAMS:
      case _PROJECT_SEARCH:
      case _PROJECT_USER:
      case _PROJECT_USERS:
      case _PROJECT_TEAM:
      case _PROJECT_ITEM:
      case _TOPIC:
      case _CHALLENGE_CATEGORY:
      case _TOPIC_COMMENTS:
      case _TOPIC_COMMENTS_THREAD:
      case _CHALLENGE:
      case _CHALLENGE_COMMENTS:
      case _CHALLENGE_COMMENTS_THREAD:
      case _CHALLENGE_ACTIVITY:
      case _CHALLENGE_MY_ACTIVITY:
      case _CLAIM:
      case _CLAIM_COMMENTS:
      case _CLAIM_COMMENTS_THREAD:
      case _PROJECT_CERTIFICATES:
      case _PROJECT_USER_CERTIFICATES:
      case _PROJECT_DISCUSSIONS:
      case _PROJECT_BOARD_POSTS:
      case _PROJECT_NEW_POST:
      case _PROJECT_BOARD_NEW_POST:
      case _PROJECT_POST_COMMENTS:
        return "project";
      case _PROJECT_HOME:
      case _TOPIC_CATEGORY:
        return "projecthome";
      default:
        return null;
    }
  }

  /* profile drawer state */
  get profileDrawerState() {
    switch (this._name) {
      case _UPDATE_DEVICE_TOKEN:
      case _GAME:
      case _LOGIN_HOME:
      case _PROJECT_LOGIN_HOME:
      case _LOGIN:
      case _LOGIN_SSO:
      case _POST_LOGIN_INTERCEPT:
      case _SSO_LOGIN_SUCCESS:
      case _FRAME_LOGIN_SUCCESS:
      case _PROJECT_LOGIN:
      case _PROJECT_LOGIN_SSO:
      case _PROJECT_POST_LOGIN_INTERCEPT:
      case _PROJECT_SSO_LOGIN_SUCCESS:
      case _PROJECT_FRAME_LOGIN_SUCCESS:
      case _REGISTER:
      case _PROJECT_REGISTER:
      case _SETTINGS:
      case _PROJECT_SETTINGS:
      case _RESEND_ACTIVATION:
      case _RESET_PASSWORD:
      case _PROJECT_RESET_PASSWORD:
      case _PROJECTS_LIST:
      case _CHALLENGE_ENQUIRY:
      case _PROJECT_USER_ENQUIRY:
      case _CLAIM_ENQUIRY:
      case _INBOX:
      case _PROJECT_INBOX:
      case _PROJECT_TEAM_INBOX:
      case _LOGIN_TOUR:
      case _PROJECT_TEAM_CREATE:
      case _TEAM_INVITE:
      case _PROJECT_TEAM_INVITE:
      case _TEAM_UPDATE:
      case _PROJECT_TEAM_UPDATE:
      case _PROJECT_REFER_A_FRIEND:
      case _CHALLENGE_A_FRIEND:
      case _FRAME:
      case _PROJECT_FRAME:
        return "no-profile-drawer";
      case _PROJECT_HOME:
      case _TOPIC_CATEGORY:
        return "project";
      default:
        return "default";
    }
  }
}

// prettier-ignore
export const
  LOGIN_HOME = new Page(_LOGIN_HOME,
   () => <LoginHomeContainer />
  ),
  LOGIN = new Page(_LOGIN,
   () => <LoginContainer />
  ),
  LOGIN_SSO = new Page(_LOGIN_SSO,
   () => <LoginSSOContainer />
  ),
  POST_LOGIN_INTERCEPT = new Page(_POST_LOGIN_INTERCEPT,
   () => <PostLoginInterceptContainer />
  ),
  LOGIN_TOUR = new Page(_LOGIN_TOUR,
   () => <TourContainer />
  ),
  REGISTER = new Page(_REGISTER, () => <RegisterContainer />),
  PROJECTS_LIST = new Page(_PROJECTS_LIST,
   () => <ProjectsListContainer />
  ),

  // SSO logins
  SSO_LOGIN_SUCCESS = new Page(_SSO_LOGIN_SUCCESS, id => <SSOLoginSuccessContainer id={id} />),
  FRAME_LOGIN_SUCCESS = new Page(_FRAME_LOGIN_SUCCESS, id => <FrameLoginSuccessContainer id={id} />),

  // project
  PROJECT_HOME = new Page(_PROJECT_HOME,
   () => <ProjectContainer />
  ),
  TOPIC_CATEGORY = new Page(_TOPIC_CATEGORY,
   id => <ProjectContainer id={id} key={_TOPIC_CATEGORY + id} />
  ),
  TOPIC = new Page(_TOPIC,
   id => <TopicContainer id={id} key={_TOPIC + id} />
  ),
  CHALLENGE_CATEGORY = new Page(_CHALLENGE_CATEGORY,
   (id, id2) => <TopicContainer id={id} categoryId={id2} key={_CHALLENGE_CATEGORY + id2} />
  ),
  CHALLENGE = new Page(_CHALLENGE,
   id => <ChallengeContainer id={id} key={_CHALLENGE + id} />
  ),
  CHALLENGE_A_FRIEND = new Page(_CHALLENGE_A_FRIEND,
   id => <ChallengeAFriendContainer id={id} key={_CHALLENGE_A_FRIEND + id} />
  ),
  PROJECT_LEADERBOARD = new Page(_PROJECT_LEADERBOARD,
   () => <LeaderboardContainer />
  ),
  PROJECT_REFER_A_FRIEND = new Page(_PROJECT_REFER_A_FRIEND,
   () => <ProjectReferAFriendContainer />
  ),
  PROJECT_REWARDS = new Page(_PROJECT_REWARDS,
   () => <RewardsContainer />
  ),
  PROJECT_TEAMS = new Page(_PROJECT_TEAMS,
   () => <TeamsContainer />
  ),
  PROJECT_TEAM_CREATE = new Page(_PROJECT_TEAM_CREATE,
   () => <TeamCreateContainer />
  ),
  PROJECT_USERS = new Page(_PROJECT_USERS,
   () => <UsersContainer />
  ),
  PROJECT_SEARCH = new Page(_PROJECT_SEARCH,
   id => <SearchChallengeContainer search={id} key={_PROJECT_SEARCH + id} />
  ),
  PROJECT_LOGIN_HOME = new Page(_PROJECT_LOGIN_HOME,
   id => <LoginHomeContainer id={id} />
  ),
  PROJECT_LOGIN = new Page(_PROJECT_LOGIN,
   id => <LoginContainer id={id} />
  ),
  PROJECT_LOGIN_SSO = new Page(_PROJECT_LOGIN_SSO,
   id => <LoginSSOContainer id={id} />
  ),
  PROJECT_POST_LOGIN_INTERCEPT = new Page(_PROJECT_POST_LOGIN_INTERCEPT,
   id => <PostLoginInterceptContainer id={id} />
  ),
  PROJECT_SSO_LOGIN_SUCCESS = new Page(_PROJECT_SSO_LOGIN_SUCCESS,
   id => <SSOLoginSuccessContainer id={id} />
  ),
  PROJECT_FRAME_LOGIN_SUCCESS = new Page(_PROJECT_FRAME_LOGIN_SUCCESS,
   id => <FrameLoginSuccessContainer id={id} />
  ),
  PROJECT_REGISTER = new Page(_PROJECT_REGISTER,
   id => <RegisterContainer id={id} />
  ),

  // profile
  USER = new Page(_USER,
   id => <ProfileContainer id={id} key={_USER + id} />
  ),
  PROFILE = new Page(_PROFILE,
   () => <ProfileContainer key={_PROFILE} />
  ),
  PROJECT_USER = new Page(_PROJECT_USER,
   id => <ProfileContainer id={id} key={_PROJECT_USER + id} />
  ),
  PROJECT_PROFILE = new Page(_PROJECT_PROFILE,
   () => <ProfileContainer key={_PROJECT_PROFILE} />
  ),

  TEAM = new Page(_TEAM,
   id => <TeamContainer id={id} />
  ),
  TEAM_INVITE = new Page(_TEAM_INVITE,
   id => <TeamInviteContainer id={id} />
  ),
  TEAM_UPDATE = new Page(_TEAM_UPDATE,
   id => <TeamUpdateContainer id={id} />
  ),
  PROJECT_TEAM = new Page(_PROJECT_TEAM,
   id => <TeamContainer id={id} key={_PROJECT_TEAM + id} />
  ),
  PROJECT_TEAM_INVITE = new Page(_PROJECT_TEAM_INVITE,
   id => <TeamInviteContainer id={id} key={_PROJECT_TEAM_INVITE + id} />
  ),
  PROJECT_TEAM_UPDATE = new Page(_PROJECT_TEAM_UPDATE,
   id => <TeamUpdateContainer id={id} key={_PROJECT_TEAM_UPDATE + id} />
  ),

  ITEM = new Page(_ITEM,
   id => <ItemContainer id={id} />
  ),
  PROJECT_ITEM = new Page(_PROJECT_ITEM,
   id => <ItemContainer id={id} key={_PROJECT_ITEM + id} />
  ),

  // achievements
  ACHIEVEMENTS = new Page(_ACHIEVEMENTS,
   () => <AchievementsContainer key={_ACHIEVEMENTS} />
  ),
  USER_ACHIEVEMENTS = new Page(_USER_ACHIEVEMENTS,
   id => <AchievementsContainer id={id} key={_USER_ACHIEVEMENTS + id} />
  ),
  PROJECT_ACHIEVEMENTS = new Page(_PROJECT_ACHIEVEMENTS,
   id => <AchievementsContainer key={_PROJECT_ACHIEVEMENTS + id} />
  ),
  PROJECT_USER_ACHIEVEMENTS = new Page(_PROJECT_USER_ACHIEVEMENTS,
    id => <AchievementsContainer id={id} key={_PROJECT_USER_ACHIEVEMENTS + id} />
  ),

  // follows
  USER_FOLLOWS = new Page(_USER_FOLLOWS,
    id => <FollowsContainer id={id} key={_USER_FOLLOWS + id} />
  ),
  PROJECT_USER_FOLLOWS = new Page(_PROJECT_USER_FOLLOWS,
    id => <FollowsContainer id={id} key={_PROJECT_USER_FOLLOWS + id} />
  ),

  // activity
  PROJECT_ACTIVITY = new Page(_PROJECT_ACTIVITY,
   () => <ActivityContainer type="project" key={_PROJECT_ACTIVITY} />
  ),
  CHALLENGE_ACTIVITY = new Page(_CHALLENGE_ACTIVITY,
   id => <ActivityContainer
      type="challenge"
      id={id}
      key={_CHALLENGE_ACTIVITY + id}
    />
  ),
  CHALLENGE_MY_ACTIVITY = new Page(_CHALLENGE_MY_ACTIVITY,
    id => <MyActivityContainer
      type="challenge"
      id={id}
      key={_CHALLENGE_MY_ACTIVITY + id}
    />
  ),

  CLAIM = new Page(_CLAIM,
   id => <ActivityContainer type="claim" id={id} key={_CLAIM + id} />
  ),

  // frames
  FRAME = new Page(_FRAME,
   id => <FrameContainer url={id} key={_FRAME + id} />
  ),
  PROJECT_FRAME = new Page(_PROJECT_FRAME,
   id => <FrameContainer url={id} key={_PROJECT_FRAME + id} />
  ),

  // bookmarks
  BOOKMARKS = new Page(_BOOKMARKS,
   () => <BookmarksContainer key={_BOOKMARKS} />
  ),
  PROJECT_BOOKMARKS = new Page(_PROJECT_BOOKMARKS,
   () => <BookmarksContainer key={_PROJECT_BOOKMARKS} />
  ),

  // notifications
  NOTIFICATIONS = new Page(_NOTIFICATIONS,
   () => <NotificationsContainer key={_NOTIFICATIONS} />
  ),
  PROJECT_NOTIFICATIONS = new Page(_PROJECT_NOTIFICATIONS,
   () => <NotificationsContainer key={_PROJECT_NOTIFICATIONS} />
  ),

  // inbox
  INBOX = new Page(_INBOX, () => <InboxContainer />),
  PROJECT_INBOX = new Page(_PROJECT_INBOX,
   id => <InboxContainer id={id} />
  ),
  PROJECT_TEAM_INBOX = new Page(_PROJECT_TEAM_INBOX,
    id => <InboxContainer
      id={id}
      showTeam={true}
    />
  ),

  // enquiry
  CHALLENGE_ENQUIRY = new Page(_CHALLENGE_ENQUIRY,
   id => <EnquiryContainer
      type="challenge"
      id={id}
      key={_CHALLENGE_ENQUIRY + id}
    />
  ),
  PROJECT_USER_ENQUIRY = new Page(_PROJECT_USER_ENQUIRY,
    id => <EnquiryContainer
      type="player"
      id={id}
      key={_PROJECT_USER_ENQUIRY + id}
    />
  ),
  CLAIM_ENQUIRY = new Page(_CLAIM_ENQUIRY,
    id => <EnquiryContainer
      type="completion"
      id={id}
      key={_CLAIM_ENQUIRY + id} />
  ),

  // settings
  SETTINGS = new Page(_SETTINGS, () => <SettingsContainer />),
  PROJECT_SETTINGS = new Page(_PROJECT_SETTINGS,
   id => <SettingsContainer id={id} />
  ),

  // comments
  TOPIC_COMMENTS = new Page(_TOPIC_COMMENTS, id => <CommentsContainer id={id} type="quest" key={TOPIC_COMMENTS + id} />),
  CHALLENGE_COMMENTS = new Page(_CHALLENGE_COMMENTS, id => <CommentsContainer id={id} type="challenge" key={CHALLENGE_COMMENTS + id} />),
  CLAIM_COMMENTS = new Page(_CLAIM_COMMENTS, id => <CommentsContainer id={id} type="claim" key={CLAIM_COMMENTS + id} />),

  // comments thread
  TOPIC_COMMENTS_THREAD = new Page(_TOPIC_COMMENTS_THREAD, (id, id2) => <CommentsThreadContainer id={id} typeId={id2}  type="topic" key={TOPIC_COMMENTS_THREAD + id + "&" + id2} />),
  CHALLENGE_COMMENTS_THREAD = new Page(_CHALLENGE_COMMENTS_THREAD, (id, id2) => <CommentsThreadContainer id={id} typeId={id2}  type="challenge" key={CHALLENGE_COMMENTS_THREAD + id + "&" + id2} />),
  CLAIM_COMMENTS_THREAD = new Page(_CLAIM_COMMENTS_THREAD, (id, id2) => <CommentsThreadContainer id={id} typeId={id2}  type="completion" key={CLAIM_COMMENTS_THREAD + id + "&" + id2} />),

  // discussions
  PROJECT_DISCUSSIONS = new Page(_PROJECT_DISCUSSIONS, id => <DiscussionsContainer id={id} type="project" key={PROJECT_DISCUSSIONS + id} />),
  PROJECT_BOARD_POSTS = new Page(_PROJECT_BOARD_POSTS, (id, id2) => <DiscussionsContainer id={id} id2={id2} type="project" key={PROJECT_BOARD_POSTS + id + "&" + id2} />),
  PROJECT_NEW_POST = new Page(_PROJECT_NEW_POST, id => <NewPostContainer id={id} type="project" key={PROJECT_BOARD_POSTS + id} />),
  PROJECT_BOARD_NEW_POST = new Page(_PROJECT_BOARD_NEW_POST, (id, id2) => <NewPostContainer id={id} id2={id2} type="project" key={PROJECT_BOARD_POSTS + id} />),
  PROJECT_POST_COMMENTS = new Page(_PROJECT_POST_COMMENTS, (id, id2) => <DiscussionCommentsContainer id={id} id2={id2} type="project" key={PROJECT_POST_COMMENTS + id} />),

  // resend activation link
  RESEND_ACTIVATION = new Page(_RESEND_ACTIVATION, () => <ResendActivationContainer />),

  // reset password
  RESET_PASSWORD = new Page(_RESET_PASSWORD, () => <ResetPasswordContainer />),
  PROJECT_RESET_PASSWORD = new Page(_PROJECT_RESET_PASSWORD,
   id => <ResetPasswordContainer id={id} />
  ),

  // certificates
  CERTIFICATES = new Page(_CERTIFICATES,
  () => <CertificatesContainer key={_CERTIFICATES} />
  ),
  USER_CERTIFICATES = new Page(_USER_CERTIFICATES,
  id => <CertificatesContainer id={id} key={_USER_CERTIFICATES + id} />
  ),
  PROJECT_CERTIFICATES = new Page(_PROJECT_CERTIFICATES,
  id => <CertificatesContainer key={_PROJECT_CERTIFICATES + id} />
  ),
  PROJECT_USER_CERTIFICATES = new Page(_PROJECT_USER_CERTIFICATES,
    id => <CertificatesContainer id={id} key={_PROJECT_USER_CERTIFICATES + id} />
  ),

  // update device token
  UPDATE_DEVICE_TOKEN = new Page(_UPDATE_DEVICE_TOKEN, (id, id2) => <UpdateDeviceTokenContainer deviceToken={id2} />),

  // default
  ERROR = new Page(_ERROR, () => <Error404Container />),

  // for Gametize app layout
  HOME = new Page(_HOME,
   () => <HomeContainer />
  ),
  FEATURED_PROJECTS = new Page(_FEATURED_PROJECTS,
   () => <FeaturedProjectsContainer />
  ),
  SEARCH_PROJECTS = new Page(_SEARCH_PROJECTS,
   id => <SearchProjectsContainer keywords={id} />
  ),
  MY_PROJECTS = new Page(_MY_PROJECTS,
   () => <MyProjectsContainer />
  ),
  GAME = new Page(_GAME,
   id => <GameContainer id={id}/>
  );
