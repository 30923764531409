import React, { Fragment } from "react";
import LoginPage from "components/Login/LoginPage";

import TextInput from "components/shared/Inputs/TextInput";
import Link from "components/shared/Link/Link";

import {
  ENABLE_EMAIL_LOGIN,
  ENABLE_LOGIN_TOPBAR,
  ENABLE_LOGIN_HOME
} from "config";

import { RESET_PASSWORD } from "App/Routes";

import localize from "lang/localize";

import "./Login.scss";
import PasswordInput from "../../components/shared/Inputs/PasswordInput";

class PwcLoginPage extends LoginPage {
  renderLoginFields() {
    if (ENABLE_EMAIL_LOGIN) {
      return (
        <Fragment>
          <h2 className="textcenter login-title">Talent Onboarding Hub</h2>
          <div className="textcenter bottommargin-20">
            It's nice to have you back!
          </div>
          <TextInput
            id="emailInput"
            value={this.props.email}
            onChange={this.props.handleEmailChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_email_placeholder_text",
              this.props.language
            )}
          />
          <PasswordInput
            id="passwordInput"
            value={this.props.password}
            onChange={this.props.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_password_placeholder_text",
              this.props.language
            )}
          />
          <div className="pure-g topmargin-10">
            <div className="pure-u-1-1 pure-u-sm-1-2">
              <p className="smalltext textcenter flex-from-sm nomargin">
                <Link to={RESET_PASSWORD} className="default">
                  {localize(
                    "login_view_forgetpassword_text",
                    this.props.language
                  )}
                </Link>
              </p>
              <div className="hide-from-sm bottommargin-20" />
            </div>
            <div className="pure-u-1-1 pure-u-sm-1-2">
              <button
                id="loginButton"
                className={
                  "button pwc-login-button fullwidth" +
                  (!!this.props.isSubmitLoading ? " disabled" : " cta")
                }
                type="submit"
                disabled={!!this.props.isSubmitLoading}
              >
                {!!this.props.isSubmitLoading
                  ? localize(
                      "nav_bar_title_generic_loading",
                      this.props.language
                    )
                  : "Login"}
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-register-container container verticalpadding">
        {this.renderTopbarlessBackButton()}
        <div
          className={
            "innerblock pure-g" +
            (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
          }
        >
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
            <form
              className="pure-form"
              onSubmit={this.handlePreSubmit.bind(this)}
            >
              {this.renderLoginFields()}
            </form>
          </div>
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
        </div>
      </div>
    );
  }
}

export default PwcLoginPage;
