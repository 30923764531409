import React, { Component } from "react";
import { connect } from "react-redux";

import {
  LOGIN,
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  POST_LOGIN_INTERCEPT,
  PROJECT_POST_LOGIN_INTERCEPT,
} from "App/Routes";
// import LoginPage from "./LoginPage";
import LoginPage from "custom-components/Login/LoginPage.js"; // CUSTOM FOR PWC

import Router from "router";
import {
  setSessionKey,
  setUserId,
  setProjectId,
  setProject,
  showAlertWithTimeout,
} from "actions";
import {
  PLAYER_URL,
  SET_PRIVACY_POLICY_ACCEPTANCE_REQUIRED,
  SECRET_KEY,
  ENABLE_LOGIN_HOME,
  ENABLE_MICROSOFT_LOGIN,
} from "config";
import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import { DEFAULT_PACKAGE, DEFAULT_PROJECT } from "services/localStorageService";
import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import {
  POST_LOGIN,
  UPDATE_USER,
  GET_TOPICS,
  GET_FACEBOOK_OAUTH,
  GET_GOOGLE_OAUTH,
  GET_MICROSOFT_OAUTH,
} from "services/api";
import loginServices from "services/loginServices";
import localize from "lang/localize";
import urlServices from "services/urlServices";

import {
  MICROSOFT_CLIENT_INSTANCE,
  MICROSOFT_APP_LOGIN_REQUEST,
  MICROSOFT_LOGIN_USE_REDIRECT,
  MICROSOFT_SESSION_TOKEN,
  MICROSOFT_SESSION,
} from "services/microsoftServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    guestEnabled:
      state.project && state.project.guestEnabled
        ? state.project.guestEnabled
        : false,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: (sessionKey) => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: (user) => {
      dispatch(setUserId(user));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setProjectId: (projectId) => {
      dispatch(setProjectId(projectId));
    },
  };
};

export class LoginContainer extends Component {
  constructor(props) {
    super(props);
    const paramEmail = decodeURIComponent(
      urlServices.getUrlParamValueByKey("email") || "",
    );
    this.state = {
      isSubmitLoading: false,
      email: paramEmail,
      password: "",
      sessionKey: null,
      userId: null,
      username: null,
      setPrivacyPolicyAcceptanceRequired:
        SET_PRIVACY_POLICY_ACCEPTANCE_REQUIRED,
      showPrivacyAgreement: null,
      marketingConsent: null,
      privacyAgreed: false,
      showGuestLoginDialog: false,
      userRecaptchaActivate: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleUpdateMarketingConsent =
      this.handleUpdateMarketingConsent.bind(this);
    this.handleUpdatePrivacyAgreed = this.handleUpdatePrivacyAgreed.bind(this);

    /* Guest Login Dialogs */
    this.handleOpenGuestLoginDialog =
      this.handleOpenGuestLoginDialog.bind(this);
    this.handleCloseGuestLoginDialog =
      this.handleCloseGuestLoginDialog.bind(this);

    /* Recaptcha */
    this.handleRecaptchaError = this.handleRecaptchaError.bind(this);
    this.handleRecaptchaExpired = this.handleRecaptchaExpired.bind(this);

    /* OAuth */
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseFacebookError = this.responseFacebookError.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseGoogleError = this.responseGoogleError.bind(this);
    this.responseMicrosoft = this.responseMicrosoft.bind(this);
    this.handleSaveSessionAndRedirect =
      this.handleSaveSessionAndRedirect.bind(this);

    /* Loading indicators */
    this.setSubmitLoading = this.setSubmitLoading.bind(this);
  }

  componentDidMount() {
    let projectId = this.props.projectId;

    if (!projectId) {
      projectId = localStorageService.getItem("last_project_id");
      localStorageService.removeItem("last_project_id");
    }

    if (!projectId) {
      projectId = localStorageService.getItem(DEFAULT_PROJECT);
    }

    if (projectId) {
      this.getProject();
    }

    if (this.props.sessionKey && this.props.userId) {
      let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

      /* loginRedirectUrl check is necessary for IE. */
      /* Somehow componentDidMount() will run for LoginContainer in IE, */
      /* even after hitting login submit where routing is supposed to occur */
      loginServices.postLoginReplaceAndNavigate(
        projectId,
        loginRedirectUrl,
        false,
      );
    } else if (MICROSOFT_LOGIN_USE_REDIRECT && ENABLE_MICROSOFT_LOGIN) {
      const parent = this;

      MICROSOFT_CLIENT_INSTANCE.handleRedirectPromise()
        .then((loginResponse) => {
          // loginResponse is null if we use project login page
          this.redirectResponseMicrosoft(parent, loginResponse);
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  }

  getProject() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (!(err || res.body.code !== 200)) {
        this.props.setProject(res.body.game);
      }
    });
  }

  /*
    Emulate routing of "login" topbar state in
    getBackLink() within TopbarContainer
  */
  getTopbarlessBackButtonRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return "";
    }
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleUpdateMarketingConsent = (event) => {
    if (event.target.checked === true) {
      this.setState({ marketingConsent: true });
    } else {
      this.setState({ marketingConsent: false });
    }
  };

  handleUpdatePrivacyAgreed = (event) => {
    if (event.target.checked === true) {
      this.setState({ privacyAgreed: true });
    } else {
      this.setState({ privacyAgreed: false });
    }
  };

  /**
   * Handle response from various login APIs
   * @param {object} response
   * @param {object} error
   * @param {object} container Needed since we cannot use "this" for Microsoft Sign In
   */
  handleLogin = (response, error, container) => {
    if (!!!container) {
      container = this;
    }

    let projectId = container.props.projectId;

    localStorageService.removeItem(DEFAULT_PROJECT);
    localStorageService.removeItem(DEFAULT_PACKAGE);

    if (response.body) {
      // Check for package/project to use based on results from login API
      if (response.body.gameId) {
        // single project app
        projectId = response.body.gameId;
        localStorageService.setItem(DEFAULT_PROJECT, response.body.gameId);
        this.props.setProjectId(response.body.gameId);
      } else if (response.body.entryProjectId) {
        // either multi-project app or project assignment
        projectId = response.body.entryProjectId;
        localStorageService.setItem(
          DEFAULT_PROJECT,
          response.body.entryProjectId,
        );
        this.props.setProjectId(response.body.entryProjectId);
      }
      if (response.body.masterId) {
        // multi-project app (package)
        localStorageService.setItem(DEFAULT_PACKAGE, response.body.masterId);
      }
    }

    if (error || response.body.code !== 200) {
      if (response.body.error) {
        container.props.showAlertWithTimeout({
          text: response.body.error,
          type: "error",
        });
      }
    } else if (response.body.sessionKey && response.body.interceptLogin) {
      /* Intercept Login */
      const INTERCEPT_LOGIN_URL =
        response.body.interceptLoginUrl +
        "?session_key=" +
        response.body.sessionKey +
        urlServices.getCallbackParams(
          response.body.sessionKey,
          this.props.language,
        );

      if (projectId) {
        Router.navigate(
          PROJECT_POST_LOGIN_INTERCEPT.format(projectId) +
            "?intercept_url=" +
            encodeURIComponent(INTERCEPT_LOGIN_URL),
        );
      } else {
        Router.navigate(
          POST_LOGIN_INTERCEPT +
            "?intercept_url=" +
            encodeURIComponent(INTERCEPT_LOGIN_URL),
        );
      }
    } else {
      let isFirstLogin = response.body.firstLogin;

      if (!isFirstLogin) {
        // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
        // 1. If user has never logged in to any account in this browser, show tour screen
        // 2. If previous login is for a different account, show tour screen
        // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
        const lastUserId = localStorageService.getItem("last_user_id");

        if (!lastUserId) {
          // First time logging in to this app in current browser
          isFirstLogin = true;
        } else if (parseInt(lastUserId) !== container.state.userId) {
          // Check if previous login in current browser is for the same account
          isFirstLogin = true;
        }

        localStorageService.setItem("last_user_id", container.state.userId);
      }

      container.handleSaveSessionAndRedirect(
        response.body.userId,
        response.body.sessionKey,
        isFirstLogin,
      );
    }
  };

  /*
    recaptchaToken set to null by default;
    pass it in only when userRecaptchaActivate is true
  */
  handleSubmit = (recaptchaRef, recaptchaToken = null) => {
    let data = {
      email: this.state.email,
      password: this.state.password,
    };

    /*
      recaptchaToken is set in the data to be passed to
      backend only when userRecaptchaActivate is true
    */
    if (recaptchaToken) {
      data = {
        email: this.state.email,
        password: this.state.password,
        recaptcha_token: recaptchaToken,
      };
    }

    pushApiGenerator(POST_LOGIN, data).end((err, res) => {
      this.setSubmitLoading(false);

      if (err || res.body.code !== 200) {
        const RECAPTCHA_TIMEOUT_ERROR_MESSAGE = "timeout-or-duplicate";
        const RECAPTCHA_GENERIC_ERROR_CODE_LIST = [
          "missing-input-secret",
          "invalid-input-secret",
          "missing-input-response",
          "invalid-input-response",
          "bad-request",
        ];

        /*
          Sometimes, the visitor may have refreshed the page, thus setting
          userRecaptchaActivate to false in the page state. However,
          userRecaptchaActivate may still be true on the backend.

          If that's the case, we check for "please verify if you are a robot"
          error message on the API call. If there is one, we set
          userRecaptchaActivate to true on the front-end, show the reCAPTCHA
          and make them re-verify.
        */
        if (res.body.error && res.body.error.indexOf("robot") !== -1) {
          this.setState({
            userRecaptchaActivate: true,
          });
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        } else if (
          /* When the reCAPTCHA API throws out errors */
          res.body.error &&
          (res.body.error.indexOf(RECAPTCHA_TIMEOUT_ERROR_MESSAGE) !== -1 ||
            RECAPTCHA_GENERIC_ERROR_CODE_LIST.indexOf(res.body.error) !== -1)
        ) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        } else {
          /* Generic errors unrelated to reCAPTCHA */
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });

          this.setState({
            userRecaptchaActivate:
              typeof res.body.userRecaptchaActivate === "boolean"
                ? res.body.userRecaptchaActivate
                : false,
          });
        }

        /* For all API failure calls, reset reCAPTCHA */
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      } else if (res.body.sessionKey && res.body.interceptLogin) {
        this.handleLogin(res, err, this);
      } else {
        this.setState(
          {
            sessionKey: res.body.sessionKey,
            userId: res.body.userId,
            username: res.body.user.name || "",
            showPrivacyAgreement: !(res.body.privacyAgreed === 1),
            marketingConsent: res.body.marketingConsent === 1,
          },
          () => {
            if (
              !(
                this.state.setPrivacyPolicyAcceptanceRequired &&
                this.state.showPrivacyAgreement
              )
            ) {
              this.handleLogin(res, err, this);
            }
          },
        );
      }
    });
  };

  handlePrivacySubmit = (event) => {
    event.preventDefault();

    /* unfortunately, user_name has to be supplied for now to get UPDATE_USER to work properly */
    let query = {
      user_name: this.state.username,
      marketing_consent: this.state.marketingConsent === true ? 1 : 0,
      privacy_agreed: this.state.privacyAgreed === true ? 1 : 0,
    };

    let req = pushApiGenerator(UPDATE_USER, query, this.state.sessionKey);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("alert_settings_update_success", this.props.language),
          type: "success",
        });

        /*
          We will assume players who have to agree
          to the new terms are on first-time login,
          thus, we will be showing the tour.
        */
        this.handleSaveSessionAndRedirect(
          this.state.userId,
          this.state.sessionKey,
          true,
        );
      }
    });
  };

  handleOpenGuestLoginDialog = (event) => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: true,
    });
  };

  handleCloseGuestLoginDialog = (event) => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: false,
    });
  };

  handleRecaptchaError(recaptchaRef) {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    this.props.showAlertWithTimeout({
      text: localize("alert_login_error_recaptcha_error", this.props.language),
      type: "error",
    });
  }

  handleRecaptchaExpired(recaptchaRef) {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    this.props.showAlertWithTimeout({
      text: localize(
        "alert_login_error_recaptcha_expired",
        this.props.language,
      ),
      type: "error",
    });
  }

  handleSaveSessionAndRedirect(userId, sessionKey, isFirstLogin) {
    localStorageService.saveState({
      user_id: userId,
      sessionKey: sessionKey,
    });

    if (!!userId && !!sessionKey) {
      this.props.setUserId(userId);
      this.props.setSessionKey(sessionKey);
    }

    // Default project ID determined by login API
    let projectId = this.props.projectId;

    if (!projectId) {
      projectId = localStorageService.getItem(DEFAULT_PROJECT);
    }

    // loads loginRedirectUrl from sessionStorage (if available)
    // eventually loginRedirectUrl will be dumped when new page is loaded
    // (not REGISTER nor LOGIN nor RESET_PASSWORD)
    // for the aforementioned dumping, see SingleProjectApp.js
    let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

    /* Use firstLogin flag to determine if tours should be displayed */
    loginServices.postLoginReplaceAndNavigate(
      projectId,
      loginRedirectUrl,
      isFirstLogin,
    );
  }

  redirectResponseMicrosoft(parent, loginResponse) {
    if (!loginResponse) {
      return;
    }

    const hasMicrosoftResponse = localStorageService.getItem(
      MICROSOFT_SESSION_TOKEN,
    );
    if (hasMicrosoftResponse != null) {
      localStorageService.removeItem(MICROSOFT_SESSION);
      return;
    }

    let query = {
      mobile: true,
      type: "login",
      request_token: loginResponse.accessToken,
      api_key: SECRET_KEY,
    };

    let req = pushApiGenerator(GET_MICROSOFT_OAUTH, query);

    req.end((err, res) => {
      if (!(err || res.body.code !== 200)) {
        // Use markers to prevent unintended logout or auto login
        localStorageService.setItem(MICROSOFT_SESSION_TOKEN, "true");
        localStorageService.setItem(MICROSOFT_SESSION, "true");
      }

      parent.handleLogin(res, err, parent);
    });
  }

  responseMicrosoft() {
    localStorageService.removeItem("last_project_id");
    localStorageService.removeItem(MICROSOFT_SESSION);

    if (MICROSOFT_LOGIN_USE_REDIRECT) {
      localStorageService.removeItem(MICROSOFT_SESSION_TOKEN);

      if (this.props.projectId) {
        // Use whitelisted redirect URL and store project id in storage instead
        localStorageService.setItem("last_project_id", this.props.projectId);

        MICROSOFT_CLIENT_INSTANCE.loginRedirect({
          ...MICROSOFT_APP_LOGIN_REQUEST,
          redirectUri:
            PLAYER_URL +
            urlServices.removeFirstSlash(
              ENABLE_LOGIN_HOME ? LOGIN_HOME : LOGIN,
            ),
        });
      } else {
        MICROSOFT_CLIENT_INSTANCE.loginRedirect(MICROSOFT_APP_LOGIN_REQUEST);
      }

      return;
    }

    const parent = this;

    MICROSOFT_CLIENT_INSTANCE.loginPopup(MICROSOFT_APP_LOGIN_REQUEST)
      .then(function (loginResponse) {
        let query = {
          mobile: true,
          type: "login",
          request_token: loginResponse.accessToken,
          api_key: SECRET_KEY,
        };

        let req = pushApiGenerator(GET_MICROSOFT_OAUTH, query);

        req.end((err, res) => {
          if (!(err || res.body.code !== 200)) {
            // Use markers to prevent unintended logout or auto login
            localStorageService.setItem(MICROSOFT_SESSION, "true");
          }

          parent.handleLogin(res, err, parent);
        });
      })
      .catch((e) => {
        parent.props.showAlertWithTimeout({
          text: localize("login_failed_text", this.props.language),
          type: "error",
        });
      });
  }

  responseFacebook(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.accessToken,
      api_key: SECRET_KEY,
    };

    let req = pushApiGenerator(GET_FACEBOOK_OAUTH, query);

    req.end((err, res) => {
      this.handleLogin(res, err, this);
    });
  }

  responseFacebookError(error) {
    return false;
  }

  responseGoogle(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.credential,
      api_key: SECRET_KEY,
    };

    let req = pushApiGenerator(GET_GOOGLE_OAUTH, query);

    req.end((err, res) => {
      this.handleLogin(res, err, this);
    });
  }

  responseGoogleError(error, details) {
    if (error && details) {
      this.props.showAlertWithTimeout({
        text: details,
        type: "error",
      });
    }
  }

  setSubmitLoading(set) {
    this.setState({
      isSubmitLoading: set,
    });
  }

  render() {
    return (
      <LoginPage
        email={this.state.email}
        password={this.state.password}
        handleEmailChange={this.handleEmailChange}
        handlePasswordChange={this.handlePasswordChange}
        handleUpdateMarketingConsent={this.handleUpdateMarketingConsent}
        handleUpdatePrivacyAgreed={this.handleUpdatePrivacyAgreed}
        handleSubmit={this.handleSubmit}
        handlePrivacySubmit={this.handlePrivacySubmit}
        showPrivacyAgreement={this.state.showPrivacyAgreement}
        marketingConsent={this.state.marketingConsent}
        privacyAgreed={this.state.privacyAgreed}
        projectId={this.props.projectId}
        topbarlessBackButtonRoute={this.getTopbarlessBackButtonRoute()}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* Recaptcha */
        userRecaptchaActivate={this.state.userRecaptchaActivate}
        handleRecaptchaError={this.handleRecaptchaError}
        handleRecaptchaExpired={this.handleRecaptchaExpired}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
        responseMicrosoft={this.responseMicrosoft}
        /* loading indicators */
        isSubmitLoading={this.state.isSubmitLoading}
        setSubmitLoading={this.setSubmitLoading}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
